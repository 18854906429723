import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Navigation`}</inlineCode>{` adalah sebuah komponen terdiri dari unsur UI yang berisikan serangkaian tindakan yang sudah ditentukan untuk memandu user menavigasi sebuah product, memungkinkan mereka berinteraksi dan membantu mereka untuk memenuhi tujuan yang sudah ditetapkan.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Tree`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABJ0lEQVQoz4VSi27CMAzs/3/iNGnaGKKCPPvKu4edrlBGxyJZdi72xU6uGaaA0UWwf7AdbNzksv9tjDdrMPkEFzJZwlQTliLGJ5+rHzZkTw384M0aaNvjLDQu0tRDFwt5D9v1UNpAG4sQE3Iu1KmHp4sTxWwx5XuHa3fHi8XbQeD9W6I1AZcROBsHay2klFBKEVkGr8k5IlpiXmWenwm7rqNCQYUSKcWa6D11aA0U4UYrzGUhyTk9EpZdwr52obRGjAthCBGtsPg8SRxaBT0mWDejd8voLwl5NCFEHS+lVBPnuRChwcfxjK+TuBEORMgkf47Mm64bbo/vqbP62PQJfT/AGFMv5ItKWfAQ8/6nbGWz2l0WJJ8NzvsV/1c2e4esxT1hjy+EfQWlBQkM0wJLngAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/788c2f355c20978b162f1e2d6efbd9c7/3cbba/tree.png",
              "srcSet": ["/static/788c2f355c20978b162f1e2d6efbd9c7/7fc1e/tree.png 288w", "/static/788c2f355c20978b162f1e2d6efbd9c7/a5df1/tree.png 576w", "/static/788c2f355c20978b162f1e2d6efbd9c7/3cbba/tree.png 1152w", "/static/788c2f355c20978b162f1e2d6efbd9c7/7dc98/tree.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Scrollspy Horizontal`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAAhklEQVQoz72S2wrEIAxE/f//3FfpJjFGLaRWW9BeKXT34RAyJMMENc5HfRPzE0PiuQmlErd14cZkntklXEVeqHrqls5o903bYK4WK+BEvwCKriaOadQQU2bsYDlIuAoDBf1YnxG1IIpICiQZXwylGPYcGramXir8xsl3S48e5Wp4m+Kv/3ACdSsKaw61234AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/1e095755de1ad678032e7956e4f7a29d/3cbba/scrollspy.png",
              "srcSet": ["/static/1e095755de1ad678032e7956e4f7a29d/7fc1e/scrollspy.png 288w", "/static/1e095755de1ad678032e7956e4f7a29d/a5df1/scrollspy.png 576w", "/static/1e095755de1ad678032e7956e4f7a29d/3cbba/scrollspy.png 1152w", "/static/1e095755de1ad678032e7956e4f7a29d/7dc98/scrollspy.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Scrollspy Vertical`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={4} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="1:2" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "394px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "200%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAoCAYAAAD+MdrbAAAACXBIWXMAAAsSAAALEgHS3X78AAABxklEQVRIx+2W667CIBCEff9XNCfqsfXSC/ZeoJc5DFqDpmrr8ackG1gTvs7stuCirDU+GYsv8At8A1hUXCgUlfoXaNi/cGGc341h/6JwnlBLDaUaSKVtMB/WQ/5SobVskp2okZQNfCERZRrHREEUjV0zglRD5Bp936NzgrluWgd4oYepRClbC8nrFmnV2Ly4BH+rVIex0TSdW8MzUOkWpzjCZvWDve/B+11DmPx+9BdVQ3DcKByA0tSuUi0yU4LMqOPctu0oYBKQzchlD1F2MO5xECXW6/UVMDaeA43lOAqx3/k2ojDAcrlEkiQPoS8tl2WJLM+RpClyM1dVZQrejFqepFAIgePxaCOKImitH6qapDDLMsRxbIFhGNoHMOeav9V1PV0hgUVRIDV2gyCwkNPpZKFDLqWcZ5lqttvtdeOzzr5WqI1C0wgqonXaY2OUUu81RfJLMRbZDNaLYN/3bQncl3kWkO8cbRPK+X9dNkBaJYxBOG1zEz9BRtd187pMy7S72WzgeR4Oh4Od+QmuViv7FszqMgdVuIqGnPO5jlMPhwvwnfHwPLw/jcfCVTd6Yrv3wUduvU/ceDe33scVfv8sfYHz4w/y+TCme1pe/QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/74b907cad025e9c4dba9db0fe46751a5/bb2b9/scrollspy-vertical.png",
              "srcSet": ["/static/74b907cad025e9c4dba9db0fe46751a5/7fc1e/scrollspy-vertical.png 288w", "/static/74b907cad025e9c4dba9db0fe46751a5/bb2b9/scrollspy-vertical.png 394w"],
              "sizes": "(max-width: 394px) 100vw, 394px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      